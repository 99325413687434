import classNames from "classnames";
import clsx from "classnames";
import { graphql, Link, StaticQuery } from "gatsby";
import * as React from "react";
import TetherComponent from "react-tether";
import {
  getNavigation,
  TypeNavigationData,
  TypeNavigationQueryResult,
  TypeSecondLevelNavigationItem,
  TypeTopLevelNavigationItem,
  TypeMetaLevelNavigationItem,
} from "../../utils/queries/navigation";
import { AmazeeIOButton, AmazeeIOButton2, AmazeeIOButton3 } from "../button/button";
import { NavHamburger } from "../hamburger/hamburger";
import queryString from "query-string";
import "./navigation-v2.scss";
import {
  PrismicImage,
  PrismicRichText,
} from "../../utils/queries/types";

import RichTextRender from "../../utils/rich-text";
import Linked from "../../utils/linkedItem";
import useDetectHover from "../../hooks/onHoverOutside";
import DownCaret from "./down-caret";
import UpCaret from "./up-caret";

type TypeNavigationProps = {
  data: TypeNavigationData;
  currentPath: string;
  isOpen?: boolean;
  children?: any;
  onSelect?: () => void;
};

const NavigationBanner = ({
  banner_style,
  banner_image,
  banner_content,
  banner_links,
  banner_link_to
}: {
  banner_style: string;
  banner_image: PrismicImage;
  banner_content: PrismicRichText;
  banner_links: TypeMetaLevelNavigationItem[];
  banner_link_to: string;
}) => {
  const banner_col_scheme = banner_style
    ? " " + banner_style.replace(/\s&\s/g, "-").toLowerCase()
    : "";

  return (
    <div className={"navigation-banner" + banner_col_scheme}>
      <div className="content container">
        <img src={banner_image.url} alt={banner_image.alt} />
        <span className="unlinked flex justify-between items-center">
        <a className="no-margin" href={banner_link_to}> 
          {RichTextRender(banner_content)}
           <span className="read-more-banner"></span>
          </a>
          {banner_links && (
            <ul className="mb-0 right-side-links">
              {/* {banner_links.map((link, index) => (
                <a
                  className="last:mr-0"
                  target="_blank"
                  href={link.banner_link_group_to.url}
                >
                  <div className="inline-block" style={{ fontWeight: 400 }}>
                    {link.banner_link_group}
                  </div>
                  <i className="fa fa-arrow-right" /> 
                </a>
              ))} */}
              <a className="last:mr-0" target="_blank" href="https://support.amazee.io/">
                <div className="inline-block" style={{ fontWeight: 400 }}>Support Center</div>
                <i className="fa fa-arrow-right" />
              </a>
              <a className="last:mr-0" target="_blank" href="https://docs.lagoon.sh/">
                <div className="inline-block" style={{ fontWeight: 400 }}>Docs</div>
                <i className="fa fa-arrow-right" />
              </a>
              <a className="last:mr-0" href="https://www.amazee.io/get-in-touch">
                <div className="inline-block" style={{ fontWeight: 400 }}>Contact</div>
                <i className="fa fa-arrow-right" />
              </a>
              <a className="last:mr-0" href="https://dashboard.amazeeio.cloud">
                <div className="inline-block" style={{ fontWeight: 400 }}>Log In</div>
                <i className="fa fa-arrow-right" />
              </a>
            </ul>
          )}
        </span>
      </div>
    </div>
  );
};

/**
 * Helper method to determine if a path is a sub-path of another one.
 */
export const isSubPathOf = (subPath: string, path: string) => {
  return ((a: string[], b: string[]) =>
    a.map((v, i) => b[i] === v).reduce((p, c) => p && c))(
    subPath.split("/").slice(0, path.split("/").length),
    path.split("/")
  );
};

export const isSubPathOfNavItem = (
  item: TypeTopLevelNavigationItem,
  currentPath: string
) => {
  if (isSubPathOf(item.primary.link_to.document.data.url, currentPath)) {
    return true;
  }

  if (item && item.items && item.items.length) {
    for (let element of item.items) {
      if (isSubPathOf(element.link_to.document.data.url, currentPath)) {
        return true;
      }
    }
  }

  return false;
};

const NOOP = () => {};

export const MobileNavigationItem: React.FC<{
  item: TypeTopLevelNavigationItem;
  currentPath: string;
  onSelect?: () => void;
  lastItem: boolean;
  index: number,
  currentIndex: number | null
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>,
}> = ({ item, currentPath, onSelect = NOOP, lastItem, index, currentIndex, setCurrentIndex }) => {
  const cls = classNames("nav__nav-item", "avenir");
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if(currentIndex === index) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [currentIndex])

  const link = (
    <div className={`px-2 ${isOpen ? `bg-amazee-solitude`: ``}`} id="mob-nav-section">
      <div className={`py-3 flex justify-between ${lastItem ? ``: `border-b border-amazee-light-grey`}`}>
        <Linked
          link_to={item.primary.link_to}
          onClick={onSelect}
          className={`text-base ${classNames({
            active: isSubPathOfNavItem(item, currentPath),
          })}`}
        >
          {RichTextRender(item.primary.label)}
        </Linked>
        {item?.items?.length ? <div onClick={() => setCurrentIndex(() => {
          if (currentIndex === index) {
            return null
          }
          return index
        })} className="flex items-center flex-1 justify-end pr-1 cursor-pointer">
          {isOpen ? <UpCaret />: <DownCaret />}
          </div> : <></>
        }
      </div>
    </div>
  );

  if (item.slice_type === "button") {
    return (
      <AmazeeIOButton classes={cls} onClick={onSelect} skipPadding={true}>
        {link}
      </AmazeeIOButton>
    );
  }

  return (
    <div className={`${cls} ${isOpen ? `bg-amazee-solitude border-b border-amazee-light-grey`: ``}`}>
      {link}
      <div className={`${isOpen ? `py-3`: ``}`}>
        {isOpen && item.items &&
          item?.items?.map((subItem, index) => {
            return (
              <MobileNavigationSubItem
                key={index}
                onSelect={onSelect}
                item={subItem}
                currentPath={currentPath}
              />
            );
          })}
      </div>
    </div>
  );
};

export const MobileNavigationSubItem: React.FC<{
  item: TypeSecondLevelNavigationItem;
  currentPath: string;
  onSelect?: () => void;
}> = ({ item, currentPath, onSelect = NOOP }) => {
  const cls = classNames(" nav__subnav-item", "avenir", "sub-menu-item", {
    active: isSubPathOf(currentPath, item.link_to.document.data.url),
  });

  const link = (
    <>
      <Linked link_to={item.link_to} onClick={onSelect} className={`font-medium text-base py-2 block`}>
        {item.with_indent && <i className="fa fa-chevron-right" />}
        {item.sub_nav_link_label.text}
      </Linked>
      {item.is_divider ? <div className="border-t my-3 w-11/12 border-amazee-light-grey"></div>: <></>}
    </>
  );

  return <div className={cls}>{link}</div>;
};

export const NavigationItem: React.FC<{
  item: TypeTopLevelNavigationItem;
  currentPath: string;
  onSelect?: () => void;
  allowedToOpenDropdown: boolean;
  dropdownChanged: (x: boolean) => void;
  isLastbtn: boolean;
}> = ({
  item,
  currentPath,
  onSelect = NOOP,
  allowedToOpenDropdown,
  dropdownChanged,
  isLastbtn,
}) => {
  const cls = classNames(
    "nav__nav-item",
    "avenir",
    item.primary.link_to.document.data.friendly_name,
    {
      active: isSubPathOfNavItem(item, currentPath),
    },
    isLastbtn ? "last-btn-style": ""
  );

  let dropdownRef = React.useRef(null);
  let innerRef = React.useRef(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useDetectHover(
    [dropdownRef, innerRef],
    false,
    300,
    dropdownChanged
  );
  const onClickHandler = () => {
    onSelect();
    setDropdownIsOpen(true);
    dropdownChanged(true);
  };
  const link = (
    <Linked
      target={
        item.primary.link_to.document.data.friendly_name === "lagoon"
          ? "_blank"
          : "_self"
      }
      className={item.primary.link_to.document.data.url}
      link_to={item.primary.link_to}
      onClick={onSelect}
    >
      {RichTextRender(item.primary.label)}
    </Linked>
  );

  if (item.slice_type === "button") {
    return (
      <AmazeeIOButton2 onClick={onSelect}>
        {link}
      </AmazeeIOButton2>
    );
  }

  return (
    <div className="d-inline" ref={dropdownRef}>
      <TetherComponent
        attachment="top center"
        constraints={[
          {
            to: "scrollParent",
            attachment: "together",
          },
        ]}
        className={
          "base-nav-dropdownbox " +
          item.primary.link_to.document.data.friendly_name
        }
        offset="-39px 0"
        renderTarget={(ref) => (
          <div ref={ref} className={`${cls} nav-display`} onMouseEnter={onClickHandler}>
            {link}
          </div>
        )}
        renderElement={(ref) => (
          <NavigationV2DropDown
            baseRef={ref}
            innerRef={innerRef}
            isActive={dropdownIsOpen && allowedToOpenDropdown}
            item={item}
            currentPath={currentPath}
          />
        )}
      />
    </div>
  );
};

// this part
export const NavigationV2DropDown: React.FC<{
  baseRef: React.RefObject<Element>;
  innerRef: React.RefObject<Element>;
  isActive: boolean;
  item: TypeTopLevelNavigationItem;
  currentPath: string;
}> = ({ innerRef, baseRef, isActive, item, currentPath }) => {
  if (!isActive) {
    return null;
  }

  const isOnPath = (url: string): boolean => {
    return (
      currentPath.replaceAll("/", "") === url.replaceAll("/", "") ||
      currentPath.startsWith(url)
    );
  };

  return (
    <nav ref={baseRef}>
      <div className="nav__dropdown-menu" ref={innerRef}>
        <div className="hover-grabber" />
        <ul>
          {item?.items?.map?.((subItem, index) => (
            <li key={index}>
              {subItem?.is_divider ? <hr className="divider-line"></hr> : <></>}
              <Linked
                link_to={subItem.link_to}
                className={clsx("nav__subnav-item", {
                  active: isOnPath(subItem.link_to.document.data.url),
                })}
              >
                {subItem.with_indent && <i className="fa fa-chevron-right" />}
                {RichTextRender(subItem.sub_nav_link_label)}
                <small
                  title={`${currentPath.replaceAll(
                    "/",
                    ""
                  )} ==? ${subItem.link_to.document.data.url.replaceAll(
                    "/",
                    ""
                  )}`}
                ></small>
              </Linked>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export const NavigationV2: React.FC<TypeNavigationProps> = ({
  data,
  currentPath,
}) => {
  const emptyMenuState = {};
  data.nav.forEach((item, index) => {
    emptyMenuState[item.primary.link_to.document.data.url] = false;
  });

  const [dropdownOpenMap, setDropdownOpenMap] = React.useState<object>(
    emptyMenuState
  );
  const [mobileIsOpen, setMobileIsOpen] = React.useState(false);

  const hasBanner = data.banner_enabled;
  const headerNavClasses = classNames("nav", {
    open: mobileIsOpen,
    "has-banner": hasBanner,
  });

  const mobileOpen = () => {
    setMobileIsOpen(true);
    if (document) {
      const body = document.body;
      body.classList.add("noscroll");
    }
  };

  const mobileClose = () => {
    setMobileIsOpen(false);
    if (document) {
      const body = document.body;
      body.classList.remove("noscroll");
    }
  };

  const mobileToggle = () => {
    if (mobileIsOpen) {
      mobileClose();
    } else {
      mobileOpen();
    }
  };

  const allowedToOpenDropdownForItem = (item: TypeTopLevelNavigationItem) => {
    for (let itemUrl in dropdownOpenMap) {
      if (
        !!dropdownOpenMap[itemUrl] &&
        itemUrl !== item.primary.link_to.document.data.url
      ) {
        return false;
      }
    }

    return true;
  };

  const setDropdownStatusForItem = (
    item: TypeTopLevelNavigationItem,
    isOpen: boolean
  ) => {
    setDropdownOpenMap({
      ...emptyMenuState,
      [item.primary.link_to.document.data.url]: isOpen,
    });
  };

  const createDropdownStatusFunction = (item: TypeTopLevelNavigationItem) => {
    return (isOpen: boolean) => {
      setDropdownStatusForItem(item, isOpen);
    };
  };

  return (
    <>
      {hasBanner && (
        <NavigationBanner
          banner_style={data.banner_style}
          banner_image={data.banner_image}
          banner_content={data.banner_content}
          banner_links={data.banner_links}
          banner_link_to={data.banner_link_to.url}
        />
      )}
      {mobileIsOpen && <div className="mobile-nav-is-open-spacer" />}
      <header className={headerNavClasses}>
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex nav__inner">
              <Link to="/" className="nav__logo-wrapper">
                {data.desktop_logo ? (
                  <img
                    className="nav__logo d-none d-md-block"
                    src={data.desktop_logo.url}
                    alt={data.desktop_logo.alt}
                  />
                ) : null}
                {data.mobile_logo ? (
                  <img
                    className="nav__logo d-block d-md-none"
                    src={data.mobile_logo.url}
                    alt={data.mobile_logo.alt}
                  />
                ) : null}
              </Link>

              <nav className="nav--desktop">
                {data?.nav?.map((item, key, arr) => (
                  <NavigationItem
                    key={key}
                    allowedToOpenDropdown={allowedToOpenDropdownForItem(item)}
                    dropdownChanged={createDropdownStatusFunction(item)}
                    item={item}
                    isLastbtn={key === arr.length - 1}
                    currentPath={currentPath}
                  />
                ))}

                <div className="d-inline-block d-lg-none order-2">
                  <NavHamburger
                    isOpen={mobileIsOpen}
                    clickHandler={mobileToggle}
                  />
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* This is in a portal so it doesn't really matter where it goes */}
        <MobileNavigationMenu
          data={data}
          currentPath={currentPath}
          isOpen={mobileIsOpen}
          onSelect={mobileClose}
        />
      </header>
    </>
  );
};

const baseUrl = [
  {
    name: "Log In",
    url: "https://dashboard.amazeeio.cloud"
  },
  {
    name: "Contact",
    url: "https://www.amazee.io/contact-us"
  },
  {
    name: "Docs",
    url: "https://docs.lagoon.sh/"
  },
  {
    name: "Support Center",
    url: "https://support.amazee.io/"
  },
];

// This is the mobile version
export const MobileNavigationMenu = ({
  isOpen,
  data,
  currentPath,
  onSelect,
}: TypeNavigationProps) => {
  const classes = classNames("nav--mobile", {
    open: isOpen,
  });
  const [currentIndex, setCurrentIndex] = React.useState<number|null>(null);

  let buttons = data.nav.filter((item) => item.slice_type === 'button');
  return (
    <nav className={classes}>
      <div className="inner">
        {data.nav.map((item, key) => (
          <MobileNavigationItem
            key={key}
            item={item}
            currentPath={currentPath}
            onSelect={onSelect}
            lastItem={4===key} // This is done to remove the styling on the fifth element.
            index={key}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex} 
          />
        ))}
        <div className="bg-amazee-light-cyan ">
          <ul className="px-2 mb-0">
            {baseUrl.map((item, i, arr) => 
              <li key={i} className={`${arr.length - 1 === i ? ``: `border-white border-b`}`}>
                 <Link to={item.url} className="py-3 block no-underline font-bold text-black text-base focus:no-underline active:no-underline">
                    {item.name}
                  </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="flex justify-between gap-5 my-4">
          {buttons?.map((item, i) => <AmazeeIOButton3 key={i} onClick={onSelect}>
            <Linked
              target={
                item.primary.link_to.document.data.friendly_name === "lagoon"
                  ? "_blank"
                  : "_self"
              }
              className={item.primary.link_to.document.data.url}
              link_to={item.primary.link_to}
              onClick={onSelect}
            >
              {RichTextRender(item.primary.label)}
            </Linked>
          </AmazeeIOButton3>)}
        </div>
      </div>
    </nav>
  );
};

export const StaticNavigationV2: React.FC<{ location: any }> = ({
  location,
}) => {
  const searchParams = queryString.parse(location.search);
  const allowedParams = ["scrollTo"];
  const filteredSearchParams = Object.keys(searchParams)
    .filter((key) => allowedParams.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = searchParams[key];
      return obj;
    }, {});

  const searchString =
    Object.keys(filteredSearchParams).length > 0
      ? `?${queryString.stringify(filteredSearchParams)}`
      : "";

  const currentPath = `${location.pathname}${searchString}`;
  const navQuery = graphql`
    {
      allPrismicNavigation(limit: 1) {
        edges {
          node {
            data {
              desktop_logo {
                alt
                copyright
                url
                thumbnails
              }
              mobile_logo {
                alt
                copyright
                url
                thumbnails
              }
              banner_enabled
              banner_image {
                alt
                url
              }
              banner_content {
                html
                text
                raw
              }
              banner_style
              banner_link
              banner_link_to {
                link_type
                url
              }
              banner_links {
                banner_link_group
                banner_link_group_to {
                  url
                }
              }
              nav {
                ... on PrismicNavigationNavNavItem {
                  primary {
                    label {
                      html
                      raw
                      text
                    }
                    link_to {
                      type
                      document {
                        ... on PrismicPageDefinitions {
                          id
                          data {
                            url
                            friendly_name
                          }
                        }
                      }
                    }
                    is_button
                  }
                  items {
                    link_to {
                      document {
                        ... on PrismicPageDefinitions {
                          id
                          data {
                            url
                            is_external
                          }
                        }
                      }
                      type
                    }
                    with_indent
                    is_divider
                    sub_nav_link_label {
                      html
                      raw
                      text
                    }
                  }
                }
                ... on PrismicNavigationNavButton {
                  id
                  primary {
                    link_to {
                      document {
                        ... on PrismicPageDefinitions {
                          id
                          data {
                            url
                            is_external
                            friendly_name
                          }
                        }
                      }
                    }
                    label {
                      text
                      raw
                      html
                    }
                  }
                  slice_type
                  slice_label
                }
              }
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={`${navQuery}`}
      render={(data: TypeNavigationQueryResult) => (
        <NavigationV2 data={getNavigation(data)} currentPath={currentPath} />
      )}
    />
  );
};

export default StaticNavigationV2;
